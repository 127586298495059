import { post, App_ID } from "./publicHeader";

// 油卡列表
class OilCard {
  async getOffline_Type_Card(merch_id, store_id, page) {
    try {
      const res = await post("offlinestore/user.Offline_Type_Card/index", {
        app_id: App_ID,
        merch_id,
        store_id,
        page,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  async getCardInfo(id) {
    try {
      const res = await post("offlinestore/user.Offline_Type_Card/detail", {
        app_id: App_ID,
        id
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  
}

export default new OilCard();