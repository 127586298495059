<template>
  <div class="oilCardDetail">
    <!-- 车队卡 -->
    <div class="oilCard" v-if="accountId&&teamcard">
      <div class="oilCard-infor" >
        <div class="header-img">
          <img src="@static/vip/touxiang.png" alt="">
        </div>
        <div class="name-type">
          <span>{{teamcard.card.name}}</span>
          <span>此卡为{{teamcard.team.name}}车队分配</span>
        </div>
      </div>
      <div class="price-cardNum">
        <div class="price"><span>{{teamcard.balance}}</span><span>元</span></div>
        <div class="cardNum"><span>{{teamcard.card_num}}</span></div>
      </div>
    </div>
    <!-- 油卡 -->
    <div class="oilCard" v-else>
      <div class="oilCard-infor">
        <div class="header-img">
          <img src="@static/vip/touxiang.png" alt="">
        </div>
        <div class="name-type">
          <span>{{Info.card_info.name}}</span>
          <span>{{Info.card_info.des}}</span>
        </div>
      </div>
      <div class="price-cardNum">
        <div class="price"><span>{{Info.balance}}</span><span>元</span></div>
        <div class="cardNum"><span>{{Info.card_num}}</span></div>
      </div>
    </div>
    <div class="toStored-value" @click='$router.push("/storedValue?cardIdx=" + cardIdx )' v-if="!accountId">去充值</div>

    <div class="card_cont">
      <cell title="交易记录" icon="balance-list-o" is-link @click="goRecord">
      </cell>
      <!-- <cell title="适用油站" icon="shop-o" is-link>
      </cell>
      <cell title="使用说明" icon="orders-o" is-link>
      </cell> -->
    </div>
  </div>
</template>

<script>
import creditshop from "@/api/creditshop";
import { Cell } from 'vant';
import OilCard from "@/api/oilCard";
export default {
  components: {
    Cell
  },
  data() {
    return {
      cardIdx: this.$route.query.id,
      Info: {
        card_info: {}
      },
      accountId: "",
      teamcard: ""
    }
  },
  mounted() {
    if (this.$route.query.hasOwnProperty("teamcard_id")) {
      this.accountId = this.$route.query.teamcard_id
      this.getDetail();
    } else {
      this.getInfo();
    }
  },
  methods: {
    // 获取详情
    async getDetail() {
      try {
        const res = await creditshop.Team_Card_detial(this.accountId);
        if (res.code == 200) {
          this.teamcard = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getInfo() {
      try {
        const res = await OilCard.getCardInfo(this.$route.query.id);
        if (res.code == 200) {
          this.Info = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    goRecord() {
      if (this.$route.query.hasOwnProperty("teamcard_id")) {
        this.$router.push({
          path: "/teamCardRecord",
          query: {
            id: this.teamcard.id
          }
        })
      } else {
        this.$router.push({
        path: "/rechargelist",
        query: {
          account_id: this.Info.id
        }
      })
      }
      
    },
  }
}
</script>


<style lang="scss" scoped>
.oilCardDetail {
  height: 100vh;
  background: #fff;
  padding: 15px 10px 0;
  position: relative;

  .oilCard {
    width: 100%;
    height: 180px;
    border-radius: 8px;
    padding: 20px 14px;
    color: #fff;
    background: linear-gradient(90deg,#34B04E,#8FD02B);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: -1px 3px 3px 2px #b9b8b8;

    .oilCard-infor {
      display: flex;
      .header-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 6px;
        img {
          width: 100%;
        }
      }

      .name-type {
        padding: 3px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        span:nth-child(1) {
          font-size: 16px;
        }
      }
    }

    .price-cardNum {
      text-align: right;
      font-size: 14px;
      .price {
        span:nth-child(1) {
          font-size: 30px;
          margin-right: 5px;
          font-weight: bold;
        }
      }
    }
  }

  .toStored-value {
    width: 240px;
    margin: 30px auto;
    height: 40px;
    background: linear-gradient(90deg,#87D02F,#30B24C);
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
  }

  .van-cell {
    padding: 14px 0;
  }
  .card_cont {
    margin-top: 50px;
    width: 100%;
    border-bottom: 1px solid #ebedf0;
  }
}
</style>